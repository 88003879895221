import * as React from 'react'
import cn from 'classnames'
import type { PropsWithoutChildren } from '../../../types/helpers'
import styles from './Loader.module.scss'
import { LoaderOutline } from '@easy-eva-icons/react'
import { ColourVariantWithTheme } from '../../../types/colour-variant'

export type LoaderProps = {
  variant?: ColourVariantWithTheme
} & PropsWithoutChildren<React.ComponentPropsWithoutRef<'div'>>

export const Loader: React.VFC<LoaderProps> = (props) => {
  const { className, variant = 'theme', ...restProps } = props

  return (
    <div className={cn(styles.loader, className)} {...restProps}>
      <span className="sr-only">Loading</span>
      <LoaderOutline
        className={cn(styles.spinner, styles[variant])}
        aria-hidden
      />
    </div>
  )
}
